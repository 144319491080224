import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import CardProject from '../CardProject';
import i18n from '../../i18n';
import Btn from '../Btn';
import { ThemeContext, themes } from '../../themes/ThemeContext';
import stylesNordr from './RelatedProjects.nordr.module.scss';
import stylesFolkhem from './RelatedProjects.folkhem.module.scss';

const getStyles = (theme) => ({
        [themes.NORDR]: stylesNordr,
        [themes.FOLKHEM]: stylesFolkhem,
    }[theme]
);

class RelatedProjects extends React.PureComponent {
    render() {
        const { title, items, projectsPage } = this.props;

        const theme = this.context;
        const isThemeFolkhem = theme === themes.FOLKHEM;
        const styles = getStyles(theme);

        const validItems = items.filter(
            (item) =>
                !Object.keys(item.shortFacts).every(
                    (key) => item.shortFacts[key] === null
                ) &&
                item.card !== undefined &&
                item.card.image !== undefined &&
                item.card.image.renditions !== undefined &&
                item.card.image.renditions.standard900 !== undefined
        );

        return (
            <aside data-nosnippet className={classNames(
                styles["RelatedProjects"],
                styles["RelatedProjects--Size"+validItems.length],
            )}>
                {validItems.length > 0 && (
                    <h2 className={styles["RelatedProjects__Title"]}>{title}</h2>
                )}
                <div className={styles["RelatedProjects__Content"]}>
                    {validItems.map((item, index) => (
                        <div
                            key={index}
                            className={styles["RelatedProjects__CardProject"]}>
                            <CardProject
                                {...item}
                                modifiers={["WithinRelatedProjects"]}
                            />
                        </div>
                    ))}
                </div>

                {projectsPage &&
                    <div className={styles["RelatedProjects__Btn"]}>
                        <Btn
                            element="a"
                            className={
                                isThemeFolkhem
                                    ? 'Btn Btn--folkhem-green'
                                    : 'Btn Btn--gray'
                            }
                            href={projectsPage}>
                            {`${i18n.t(
                                'relatedProjects.showMore'
                            )}`}
                        </Btn>
                    </div>
                }
            </aside>
        );
    }
}

RelatedProjects.propTypes = {
    title: PropTypes.string,
    items: PropTypes.array,
};

RelatedProjects.defaultProps = {
    items: [],
};

RelatedProjects.contextType = ThemeContext;

export default RelatedProjects;
